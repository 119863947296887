import * as React from "react"
import Layout from "../components/layout"
import StudentProfileMain from "../components/studentProfile"
import { Seo } from "../components/seo"
import {graphql} from 'gatsby';

const StudentProfilePage = () => (
  <Layout>
    <Seo title="Apprende Online | Perfil" />
    <StudentProfileMain />
  </Layout>
)

export default StudentProfilePage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["index"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;