import React from 'react';
import Footer from '../footer/footer';
import HeaderFour from '../header/headerFour';
import StudentProfileSection from './studentProfileSection';

const index = () => {
    return (
        <>
            <HeaderFour />
            <main>
                <StudentProfileSection />
            </main>
            <Footer />
        </>
    );
};

export default index;