import { Link } from 'gatsby';
import React from 'react';
import { useTranslation } from "react-i18next";
const UserEnrolledCourses = ({ studentData }) => {

    const { t } = useTranslation();

    return (
        <>
            {studentData &&
                <>
                    {studentData.enrolledCourses.map((data, index) => (
                        <div className="col-xl-4 col-lg-6 col-md-6" key={index}>
                            <div className="course-wrapper-2 mb-30">
                                <div className="student-course-img">
                                    <Link to={"/course-video/" + data.course.id}><img src={data.course.introImage} alt="course-img" /></Link>
                                </div>
                                <div className="student-course-footer">

                                    <div className="student-course-text">
                                        <h3><Link to={"/course-video/" + data.course.id}>{data.course.name}</Link></h3>
                                    </div>
                                    <div className="portfolio-user">
                                        <div className="user-icon">
                                            <Link to={"/instructor-profile/" + data?.course.teacher?.id}><i className="fas fa-user"></i>{data?.course.teacher?.firstName} {data?.course.teacher?.lastName}</Link>
                                        </div>
                                    </div>
                                    <div className="rating-row mb-10">
                                        <div className="progress">
                                            <div className="progress-bar progress-bar1 wow fadeInLeft" style={{ width: `${data.course.courseProgress}%` }}></div>
                                        </div>
                                        <div className="progress-tittle">
                                            <h6><span>{data.course.courseProgress}%</span> {t('Completed')}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </>
            }
        </>
    );
};

export default UserEnrolledCourses;