import React from 'react';
import StudentWishlistCourse from './studentWishlistCourse';
import UserEnrolledCourses from './userEnrolledCourses';
import * as Yup from 'yup';
import { BASE_URL } from '../../../config';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { Link, navigate } from 'gatsby';
import { setLogoutAction } from '../../redux/auth/authSlice';
import axiosWithAuth from '../../utils/axios';
import { useTranslation } from "react-i18next";
const StudentProfileSection = () => {

    const { t } = useTranslation();

    const { isAuth, userData } = useSelector(state => state.auth);
    const dispatch = useDispatch();

    React.useEffect(() => {
        if (!isAuth) {
            navigate("/")
        }
    }, [isAuth])

    const [studentData, setStudentData] = React.useState(null)

    const [initialValues, setInitialValues] = React.useState({
        firstName: "",
        lastName: "",        
        email: ""
    })

    React.useEffect(() => {
        getStudent();
    }, [userData])

    const getStudent = async () => {
        try {
            const result = await axiosWithAuth().get(BASE_URL + "/students/me")
            setStudentData(result.data)
            setInitialValues(result.data)
        } catch (error) {
            dispatch(setLogoutAction())
            localStorage.clear();
            navigate("/")
        }
    }

    const handleLogout = () => {
        dispatch(setLogoutAction())
        localStorage.clear();
        navigate("/")
    }



    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required('El nombre es obligatorio'),
        lastName: Yup.string().required('El apellido es obligatorio'),
        email: Yup.string().email('El correo electrónico no es válido').required('El correo electrónico es obligatorio')
    });

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            setSubmitting(true)
            const result = await axiosWithAuth().put(BASE_URL + "/students/me", values)
            setSubmitting(false)
        } catch (error) {

        }
    }

    return (
        studentData &&
        <div className="course-details-area pb-100">
            <div className="container">
                <div className="student-profile-author pb-30">
                    <div className="student-profile-author-img">
                        <img src={studentData.photo} style={{ width: '100px' }} alt="student photo" />
                    </div>
                    <div className="student-profile-author-text">
                        <span>{t('Welcome')},</span>
                        <h3 className='student-profile-author-name'>{studentData.firstName} {studentData.lastName}</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-3 col-lg-4">
                        <div className="student-profile-sidebar mb-30">
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id="home-tab" data-bs-toggle="tab"
                                        data-bs-target="#home" type="button" role="tab" aria-controls="home"
                                        aria-selected="true"><i className="fas fa-tachometer-alt-fast"></i>
                                        Dashboard</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="profile-tab" data-bs-toggle="tab"
                                        data-bs-target="#profile" type="button" role="tab" aria-controls="profile"
                                        aria-selected="false"><i className="fas fa-user"></i> {t('MyProfile')}</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="contact-tab" data-bs-toggle="tab"
                                        data-bs-target="#contact" type="button" role="tab" aria-controls="contact"
                                        aria-selected="false"><i className="fas fa-graduation-cap"></i> {t('MyCourses')}</button>
                                </li>
                                {/* <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="wishlist-tab" data-bs-toggle="tab"
                                        data-bs-target="#wishlist" type="button" role="tab" aria-controls="wishlist"
                                        aria-selected="false"><i className="fas fa-bookmark"></i> Mi Lista de deseos</button>
                                </li> */}
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="setting-tab" data-bs-toggle="tab"
                                        data-bs-target="#setting" type="button" role="tab" aria-controls="setting"
                                        aria-selected="false"><i className="fas fa-cog"></i> {t('Settings')}</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button onClick={() => { navigate("/contact") }} className="nav-link" type="button"
                                        aria-selected="false" ><i className="fas fa-life-ring"></i> {t('Help')}</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button onClick={() => { handleLogout() }} className="nav-link" id="logout-tab" data-bs-toggle="tab"
                                        data-bs-target="#logout" type="button" role="tab" aria-controls="logout"
                                        aria-selected="false" ><i className="fas fa-sign-out-alt"></i> {t('Logout')} </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-xl-9 col-lg-8">
                        <div className="student-profile-content">
                            <div className="tab-content" id="myTabContent">
                                {/* Dashboard Tab */}
                                <div className="tab-pane fade show active" id="home" role="tabpanel"
                                    aria-labelledby="home-tab">
                                    <h4 className='mb-25'>Dashboard</h4>
                                    <div className="student-profile-content-fact">
                                        <div className="row">
                                            <div className="col-xl-4 col-lg-6 col-md-4">
                                                <div className="counter-wrapper text-center mb-30">
                                                    <div className="counter-icon">
                                                        <div className="counter-icon-wrap">
                                                            <svg id="layer2" xmlns="http://www.w3.org/2000/svg"
                                                                width="50.897" height="56.553"
                                                                viewBox="0 0 50.897 56.553">
                                                                <path id="path3518"
                                                                    d="M26.3.646,1.793,13.689a.834.834,0,0,0,.048,1.522L16.07,21.734h21.4L51.7,15.211a.834.834,0,0,0,.05-1.522L27.244.647a.9.9,0,0,0-.473-.117A1.023,1.023,0,0,0,26.3.646Z"
                                                                    transform="translate(-1.323 -0.529)"
                                                                    fill="#5299d3" />
                                                                <path id="path3534"
                                                                    d="M16.66,2.911a35.363,35.363,0,0,0-7.55.718,9.782,9.782,0,0,0-2.456.841,3.065,3.065,0,0,0-.858.648,1.7,1.7,0,0,0-.451,1.106v7.4l-.077.893a17.977,17.977,0,0,1,22.781,0l-.077-.891v-7.4a1.7,1.7,0,0,0-.451-1.106,3.056,3.056,0,0,0-.856-.648,9.78,9.78,0,0,0-2.456-.841,35.363,35.363,0,0,0-7.55-.718Z"
                                                                    transform="translate(8.788 5.574)" fill="#a0c7e7" />
                                                                <path id="path3604"
                                                                    d="M9.111,3.629a9.782,9.782,0,0,0-2.456.841,3.065,3.065,0,0,0-.858.648,1.7,1.7,0,0,0-.451,1.106v7.4l-.077.893a17.891,17.891,0,0,1,1.962-1.388V6.225a1.7,1.7,0,0,1,.451-1.106A3.066,3.066,0,0,1,8.54,4.47,9.782,9.782,0,0,1,11,3.629a34,34,0,0,1,6.607-.7c-.316-.006-.62-.02-.943-.02a35.363,35.363,0,0,0-7.55.718Z"
                                                                    transform="translate(8.788 5.574)" fill="#74addc" />
                                                                <path id="rect3599"
                                                                    d="M18.372,4.5h1.885A14.107,14.107,0,0,1,34.395,18.636V33.67H4.233V18.636A14.107,14.107,0,0,1,18.372,4.5Z"
                                                                    transform="translate(6.135 9.64)" fill="#356287" />
                                                                <path id="path3610"
                                                                    d="M18.372,4.5A14.107,14.107,0,0,0,4.233,18.636V33.669H6.118V18.636A14.107,14.107,0,0,1,20.257,4.5Z"
                                                                    transform="translate(6.135 9.64)" fill="#2c5170" />
                                                                <path id="path3506"
                                                                    d="M15.317,11.649a14.343,14.343,0,0,0-13.2,14.545v1.451a.943.943,0,0,0,.939.946H46.418a.943.943,0,0,0,.939-.946V26.193a14.341,14.341,0,0,0-13.2-14.545H15.317Z"
                                                                    transform="translate(0.711 27.962)"
                                                                    fill="#5299d3" />
                                                                <path id="path3508"
                                                                    d="M7.827,9.922a.943.943,0,0,0-.948.939v8.909a.943.943,0,0,0,.948.939h9.42a.943.943,0,0,0,.946-.939V10.861a.943.943,0,0,0-.946-.939Z"
                                                                    transform="translate(12.912 23.538)" fill="#fca" />
                                                                <path id="path3510"
                                                                    d="M7.827,9.922a.943.943,0,0,0-.948.939v2.723a9.605,9.605,0,0,0,11.314,0V10.861a.943.943,0,0,0-.946-.939Z"
                                                                    transform="translate(12.913 23.538)"
                                                                    fill="#ffb889" />
                                                                <path id="path3512"
                                                                    d="M15.318,11.648a14.345,14.345,0,0,0-13.2,14.546v1.451a.943.943,0,0,0,.939.946H4.941A.943.943,0,0,1,4,27.645V26.194A14.345,14.345,0,0,1,17.2,11.648H15.318Z"
                                                                    transform="translate(0.711 27.961)"
                                                                    fill="#3385c8" />
                                                                <path id="path3514"
                                                                    d="M8.661,11.126,5.83,13.95a.943.943,0,0,0,0,1.329l6.6,6.6a.943.943,0,0,0,1.329,0l2.163-2.158,2.165,2.158a.943.943,0,0,0,1.329,0l6.6-6.6a.943.943,0,0,0,0-1.329L23.19,11.126a.942.942,0,0,0-1.337,0l-5.928,5.928L9.99,11.126a.933.933,0,0,0-1.329,0Z"
                                                                    transform="translate(9.523 25.911)"
                                                                    fill="#a0c7e7" />
                                                                <path id="path3520"
                                                                    d="M8.387,7.087a3.625,3.625,0,0,0-3.514,3.7A3.63,3.63,0,0,0,8.387,14.5a3.252,3.252,0,0,0,.808-.114c0-.007,0-.013,0-.02V7.189a3.341,3.341,0,0,0-.8-.1Z"
                                                                    transform="translate(7.773 16.275)"
                                                                    fill="#ffb889" />
                                                                <path id="path3522"
                                                                    d="M15.171,10.79A3.63,3.63,0,0,1,11.656,14.5a3.252,3.252,0,0,1-.808-.114c0-.007,0-.013,0-.02V7.189a3.341,3.341,0,0,1,.8-.1,3.625,3.625,0,0,1,3.514,3.7Z"
                                                                    transform="translate(23.084 16.275)" fill="#fca" />
                                                                <path id="path3528"
                                                                    d="M13.067,5.316a.943.943,0,0,0-.954.933A3.829,3.829,0,0,1,8.28,10.055H6.7a.943.943,0,0,0-.939.939v4.732a9.637,9.637,0,0,0,19.273,0V10.994a.943.943,0,0,0-.939-.939h-5.54a4.624,4.624,0,0,1-4.571-3.938.943.943,0,0,0-.919-.8Z"
                                                                    transform="translate(10.051 11.736)" fill="#fca" />
                                                                <path id="path3530" d="M4.8,3.167l-1.887,1v13.16H4.8Z"
                                                                    transform="translate(2.743 6.23)" fill="#eee" />
                                                                <path id="path3532"
                                                                    d="M3.592,6.614a.943.943,0,0,0-.946.939v8.511a.943.943,0,0,0,.946.946H5.473a.943.943,0,0,0,.939-.946V7.553a.943.943,0,0,0-.939-.939Z"
                                                                    transform="translate(2.067 15.063)"
                                                                    fill="#ffca28" />
                                                                <path id="path3537"
                                                                    d="M3.592,6.614a.943.943,0,0,0-.946.939v8.512a.943.943,0,0,0,.946.946H5.473a.943.943,0,0,1-.943-.946V7.553a.943.943,0,0,1,.943-.939Z"
                                                                    transform="translate(2.067 15.063)"
                                                                    fill="#ecb200" />
                                                                <path id="path3542"
                                                                    d="M6.7,6.646a.943.943,0,0,0-.939.939v4.731a9.613,9.613,0,0,0,6.526,9.1,10.337,10.337,0,0,1-5.374-9.068V7.255a1.014,1.014,0,0,1,.206-.609Z"
                                                                    transform="translate(10.051 15.145)"
                                                                    fill="#ffb889" />
                                                                <g id="Group_2872" data-name="Group 2872"
                                                                    transform="translate(11.311 50.9)">
                                                                    <path id="path3544"
                                                                        d="M5.483,14.818A.943.943,0,0,0,4.5,15.84v4.62H6.379V15.84A.943.943,0,0,0,5.483,14.818Z"
                                                                        transform="translate(-4.498 -14.817)"
                                                                        fill="#3385c8" />
                                                                    <path id="path3547"
                                                                        d="M12.891,14.818a.943.943,0,0,0-.981,1.022v4.62h1.878V15.84a.943.943,0,0,0-.9-1.022Z"
                                                                        transform="translate(14.485 -14.817)"
                                                                        fill="#3385c8" />
                                                                </g>
                                                            </svg>
                                                        </div>
                                                        <div className="count-number">
                                                            <span className="counter">{studentData.enrolledCourses.length}</span>
                                                            <p>{t('MyCourses')}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12">
                                                <h4 className='mb-25'>{t('CoursesInProgress')}</h4>
                                                {studentData.enrolledCourses.map((data) => (
                                                    <div key={data.course.id} className="inprogress-course mb-30">
                                                        <div className="inprogress-course-img">

                                                            <Link to={"/course-video/" + data.course.id}><img src={data.course.introImage} alt="..." /></Link>
                                                        </div>
                                                        <div className="inprogress-course-text">
                                                            {/* <div className="inprogress-course-rating">
                                                            <a href="#"><i className="fas fa-star"></i></a>
                                                            <a href="#"><i className="fas fa-star"></i></a>
                                                            <a href="#"><i className="fas fa-star"></i></a>
                                                            <a href="#"><i className="fas fa-star"></i></a>
                                                            <a href="#"><i className="fas fa-star"></i></a>
                                                            <span>5.00</span>
                                                        </div> */}
                                                            <h4 className='inprogress-course-title'>
                                                                <Link to={"/course-video/" + data.course.id}>{data.course.name}</Link>
                                                            </h4>
                                                            {/* <div className="inprogress-course-lesson mb-15">
                                                            <span>Completed Lessons :</span>
                                                            <h6><span>07</span> of <span>10</span> lessons</h6>
                                                        </div> */}
                                                            <div className="rating-row mb-10">
                                                                <div className="progress">
                                                                    <div className="progress-bar progress-bar1 wow fadeInLeft" style={{ width: `${data.course.courseProgress}%` }}></div>
                                                                </div>
                                                                <div className="progress-tittle">
                                                                    <h6><span>{data.course.courseProgress}%</span> {t('Completed')}</h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}

                                            </div>
                                        </div>

                                    </div>
                                </div>
                                {/* Profile Tab */}
                                <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                    <h4 className='mb-25'>{t('MyProfile')}</h4>
                                    <ul className='student-profile-info'>
                                        <li>
                                            <h5>{t('RegisterDate')}:</h5>
                                            <span>{new Date(studentData.createdAt).toLocaleDateString()}</span>
                                        </li>
                                        <li>
                                            <h5>{t('FirstName')}:</h5>
                                            <span>{studentData.firstName}</span>
                                        </li>
                                        <li>
                                            <h5>{t('LastName')} :</h5>
                                            <span>{studentData.lastName}</span>
                                        </li> 
                                        <li>
                                            <h5>{t('Email')} :</h5>
                                            <span>{studentData.email}</span>
                                        </li>                                       
                                    </ul>
                                    <br />
                                </div>
                                {/* Courses Tab */}
                                <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                                    <h4 className='mb-25'>{t('MyCourses')}</h4>
                                    <div className="student-profile-enroll">
                                        <ul className="nav mb-30" id="myTab" role="tablist">
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link active" id="enrolled-tab" data-bs-toggle="tab"
                                                    data-bs-target="#enrolled" type="button" role="tab"
                                                    aria-controls="enrolled" aria-selected="true">{t('MyCourses')} ({studentData.enrolledCourses.length})</button>
                                            </li>
                                            {/* <li className="nav-item" role="presentation">
                                                <button className="nav-link" id="completed-tab" data-bs-toggle="tab"
                                                    data-bs-target="#completed" type="button" role="tab"
                                                    aria-controls="completed" aria-selected="false">Cursos Finalizados ({studentData.completedCourses.length})</button>
                                            </li> */}
                                        </ul>
                                        <div className="tab-content" id="myTabContent">
                                            <div className="tab-pane fade show active" id="enrolled" role="tabpanel"
                                                aria-labelledby="enrolled-tab">
                                                <div className="student-profile-enrolled-course">
                                                    <div className="row">
                                                        <UserEnrolledCourses studentData={studentData} />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="tab-pane fade" id="completed" role="tabpanel"
                                                aria-labelledby="completed-tab">
                                                <div className="student-profile-enrolled-course">
                                                    <p>No tiene cursos completados todavía.</p>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                {/* Wishlist Tab */}
                                <div className="tab-pane fade" id="wishlist" role="tabpanel" aria-labelledby="wishlist-tab">
                                    <h4 className='mb-25'>Wishlist</h4>
                                    <div className="student-profile-wishlist">
                                        <div className="row">
                                            <StudentWishlistCourse />
                                        </div>
                                    </div>
                                </div>
                                {/* Reviews Tab */}
                                <div className="tab-pane fade" id="reviews" role="tabpanel" aria-labelledby="reviews-tab">
                                    <h4 className='mb-25'>Reviews</h4>
                                    <div className="student-profile-reviews">
                                        <div className="student-profile-reviews-item mb-30">
                                            <div className="student-profile-reviews-course-title">
                                                <h5>WordPress Development Course for Plugins and Themes</h5>
                                            </div>
                                            <div className="student-profile-reviews-text">
                                                <div className="student-profile-reviews-text-wrap mb-20">
                                                    <div className="student-profile-review-icon">
                                                        <a href="#"><i className="fas fa-star"></i></a>
                                                        <a href="#"><i className="fas fa-star"></i></a>
                                                        <a href="#"><i className="fas fa-star"></i></a>
                                                        <a href="#"><i className="fas fa-star"></i></a>
                                                        <a href="#"><i className="fas fa-star"></i></a>
                                                    </div>
                                                    <div className="student-profile-review-update">
                                                        <button type='button'
                                                            className='student-profile-review-update-btn'><i
                                                                className="far fa-edit"></i> Edit</button>
                                                        <button type='button'
                                                            className='student-profile-review-update-btn'><i
                                                                className="far fa-trash-alt"></i> Delete</button>
                                                    </div>
                                                </div>
                                                <div className="student-profile-review-content">
                                                    <p>Customer support is very quick, within same day or a few hours
                                                        even. Design and look is excellent for the target industry of
                                                        the Template.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="student-profile-reviews-item mb-30">
                                            <div className="student-profile-reviews-course-title">
                                                <h5>Data Science Real-Life Data Science Exercises Included</h5>
                                            </div>
                                            <div className="student-profile-reviews-text">
                                                <div className="student-profile-reviews-text-wrap mb-20">
                                                    <div className="student-profile-review-icon">
                                                        <a href="#"><i className="fas fa-star"></i></a>
                                                        <a href="#"><i className="fas fa-star"></i></a>
                                                        <a href="#"><i className="fas fa-star"></i></a>
                                                        <a href="#"><i className="fas fa-star"></i></a>
                                                        <a href="#"><i className="fas fa-star"></i></a>
                                                    </div>
                                                    <div className="student-profile-review-update">
                                                        <button type='button'
                                                            className='student-profile-review-update-btn'><i
                                                                className="far fa-edit"></i> Edit</button>
                                                        <button type='button'
                                                            className='student-profile-review-update-btn'><i
                                                                className="far fa-trash-alt"></i> Delete</button>
                                                    </div>
                                                </div>
                                                <div className="student-profile-review-content">
                                                    <p>Very nice design and awesome design elements</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Orders Tab */}
                                <div className="tab-pane fade" id="history" role="tabpanel" aria-labelledby="history-tab">
                                    <h4 className='mb-25'>Order History</h4>
                                    <div className="student-profile-orders-wrapper">
                                        <div className="student-profile-orders">
                                            <div className="student-profile-order custom-height-80">
                                                <div className="student-profile-order-name">
                                                    <span>Basic</span>
                                                </div>
                                                <div className="student-profile-order-price">
                                                    <span>$19</span>
                                                </div>
                                                <div className="student-profile-order-duration">
                                                    <span>Quarterly</span>
                                                </div>
                                                <div className="student-profile-order-status">
                                                    <span>verified</span>
                                                </div>
                                                <div className="student-profile-order-method">
                                                    <span>sslcommerz</span>
                                                </div>
                                                <div className="student-profile-order-date">
                                                    <span>20-06-2022</span>
                                                </div>
                                                <div className="student-profile-order-renew">
                                                    <button type='button' className='edu-btn'>Renew</button>
                                                </div>
                                            </div>
                                            <div className="student-profile-order custom-height-80">
                                                <div className="student-profile-order-name">
                                                    <span>Standerd</span>
                                                </div>
                                                <div className="student-profile-order-price">
                                                    <span>$34</span>
                                                </div>
                                                <div className="student-profile-order-duration">
                                                    <span>Half-yearly</span>
                                                </div>
                                                <div className="student-profile-order-status">
                                                    <span>verified</span>
                                                </div>
                                                <div className="student-profile-order-method">
                                                    <span>sslcommerz</span>
                                                </div>
                                                <div className="student-profile-order-date">
                                                    <span>18-06-2022</span>
                                                </div>
                                                <div className="student-profile-order-renew">
                                                    <button type='button' className='edu-btn'>Renew</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Settins Tab */}
                                <div className="tab-pane fade" id="setting" role="tabpanel" aria-labelledby="setting-tab">
                                    <h4 className='mb-25'>{t('Settings')}</h4>
                                    <div className="student-profile-enroll">
                                        <ul className="nav mb-30" id="myTab" role="tablist">
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link active" id="profileA-tab" data-bs-toggle="tab"
                                                    data-bs-target="#profileA" type="button" role="tab"
                                                    aria-controls="profileA" aria-selected="true">{t('Profile')}</button>
                                            </li>
                                            {/* <li className="nav-item" role="presentation">
                                                <button className="nav-link" id="password-tab" data-bs-toggle="tab"
                                                    data-bs-target="#password" type="button" role="tab"
                                                    aria-controls="password" aria-selected="false">Password</button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link" id="completedA-tab" data-bs-toggle="tab"
                                                    data-bs-target="#completedA" type="button" role="tab"
                                                    aria-controls="completedA" aria-selected="false">Social</button>
                                            </li> */}
                                        </ul>
                                        <div className="tab-content" id="myTabContent">
                                            <div className="tab-pane fade show active" id="profileA" role="tabpanel"
                                                aria-labelledby="profileA-tab">
                                                <div className="student-profile-settings">
                                                    <div className="student-profile-setting-img mb-40">
                                                        <div className="student-profile-setting-cover-img" style={{ backgroundImage: "url(/assets/img/slider/course-slider.jpg)" }}></div>
                                                        <div className="student-profile-setting-author-img">
                                                            <img src={initialValues.photo} alt="" />
                                                        </div>
                                                    </div>
                                                    <Formik
                                                        initialValues={initialValues}
                                                        enableReinitialize={true}
                                                        validationSchema={validationSchema}
                                                        onSubmit={handleSubmit}>
                                                        {({ values, isSubmitting, handleSubmit }) => (

                                                            <Form >
                                                                <div className="row">
                                                                    <div className="col-md-12">

                                                                        <div className="signup-form-wrapper">
                                                                            <div className="signup-input-wrapper">
                                                                                <Field type="text" name="firstName" placeholder="Nombres" />
                                                                                <ErrorMessage component="div" className='text-danger' name="firstName" />
                                                                                <Field type="text" name="lastName" placeholder="Apellidos" />
                                                                                <ErrorMessage component="div" className='text-danger' name="lastName" />
                                                                            </div>                                                                                                                                                        
                                                                            <div className="signup-input-wrapper">
                                                                                <Field type="email" name="email" placeholder="Correo Electrónico" />
                                                                                <ErrorMessage component="div" className='text-danger' name="email" />
                                                                            </div>
                                                                            <div className="sing-buttom mb-20">
                                                                                <button type='submit' disabled={isSubmitting} className="sing-btn">{isSubmitting ? t('Loading') : t('Save')}</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Form>

                                                        )}

                                                    </Formik>

                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="password" role="tabpanel"
                                                aria-labelledby="password-tab">
                                                <form action="#">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="contact-from-input mb-20">
                                                                <label htmlFor="Current">Current Password</label>
                                                                <input id='Current' type="password"
                                                                    placeholder="Type password" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="contact-from-input mb-20">
                                                                <label htmlFor="New">New Password</label>
                                                                <input id='New' type="password"
                                                                    placeholder="Type password" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="contact-from-input mb-20">
                                                                <label htmlFor="Retype">Re-type New Password</label>
                                                                <input id='Retype' type="password"
                                                                    placeholder="Type password" />
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <div className="cont-btn mb-20 mt-10">
                                                                <button type='button' className="cont-btn">Update
                                                                    Profile</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                            <div className="tab-pane fade" id="completedA" role="tabpanel"
                                                aria-labelledby="completedA-tab">
                                                <div className="student-social-profile-link">
                                                    <span className='mb-20'>Social Profile Link</span>
                                                    <form action="#">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="contact-from-input mb-20">
                                                                    <input type="text"
                                                                        placeholder="Write Your Facebook URL" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="contact-from-input mb-20">
                                                                    <input type="text"
                                                                        placeholder="Write Your Twitter URL" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="contact-from-input mb-20">
                                                                    <input type="text"
                                                                        placeholder="Write Your Instagram URL" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="contact-from-input mb-20">
                                                                    <input type="text"
                                                                        placeholder="Write Your Linkedin URL" />
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-12 ">
                                                                <div className="cont-btn mb-20 mt-10">
                                                                    <button type='button' className="cont-btn">Update Profile</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StudentProfileSection;